<template>
  <div class="contact">
    <div class="banner">
      <img src="@/assets/contact/contact.png" alt="" />
    </div>
    <div class="location">
      <div class="location_left">
        <img src="@/assets/contact/map_icon.png" alt="" />
      </div>
      <div class="location_right">
        <p class="title">山东中飞科技有限公司</p>
        <p>服务热线：0531-88871088</p>
        <p>企业邮箱：zf@sdzft.com</p>
        <p>公司地址:山东省济南市高新区颖秀路山大科技园</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.location {
  width: 1460px;
  margin: 0 auto;
  margin-top: 97px;
  margin-bottom: 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .location_left {
    position: relative;
    width: 779px;
    height: 573px;
    background: url("../../assets/contact/map.png") no-repeat;
    background-size: cover;
    img {
      position: absolute;
      top: 222px;
      right: 325px;
    }
  }
  .location_right {
    .title {
      font-size: 30px;
    }
    p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      color: #181818;
      line-height: 48px;
    }
  }
}
</style>
